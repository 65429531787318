import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./components/store/index";
import "./libraries/qrcodejs/jquery.min.js";
import "./libraries/qrcodejs/qrcode.js";
import Modal from "./components/Modal/Modal.vue";
const app = createApp(App)

app
    .component("Modal", Modal)
    .use(router)
    .use(store)
    .mount("#app");
