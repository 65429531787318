<script>
import { sidebarWidth, collapsed, toggleSidebar } from "./state";
import * as api from "../Api/Api";
import { url_to_files } from "../Api/Api"
export default {
  components: { toggleSidebar },
  data() {
    return {
      role: localStorage.getItem("role"),
      branch_id: localStorage.getItem("branch_id"),
      logo: null,
      url_to_files: url_to_files
    };
  },
  setup() {
    return { collapsed, sidebarWidth, toggleSidebar };
  },
  created() {
    api.branch(this.branch_id).then((res) => {
      this.logo = res.data.logo?.logo;
    });
    window.onclick = (event) => {
      if (!event.target.closest(".v-toggle-button"))
        this.$store.dispatch("toggleNav", false);
    };
  },
  methods: {},
  computed: {
    show_nav() {
      return this.$store.getters.show_nav;
    },
    current_user() {
      return this.$store.getters.user;
    },
    routers() {
      const role = this.role;
      return [
        {
          icon: "fa fa-cash-register",
          name: "Kassalar",
          path: "/kassa",
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-chart-line",
          name: "Hisobotlar",
          path: `/statistic/${this.branch_id}`,
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-user-group",
          name: "Hodimlar",
          path: `/hodimlar/${this.branch_id}`,
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-handshake",
          name: "Mijozlar",
          path: "/mijozlar",
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-hand-holding-usd",
          name: "Nasiyalar",
          path: "/nasiyalar",
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-undo",
          name: "Vozvrat",
          path: "/return",
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-truck-loading",
          name: "Ta'minot",
          path: `/taminot/${this.branch_id}`,
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-folder",
          name: "Mahsulotlar",
          path: "/categories",
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-box",
          name: "Mahsulotlar qoldig'i",
          path: `/revision/${this.branch_id}`,
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-wallet",
          name: "Chiqim",
          path: `/expense/${this.branch_id}`,
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-user",
          name: "Admin",
          path: `/admin/${this.branch_id}`,
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-file-invoice-dollar",
          name: "Foyda hisoboti",
          path: `/benefit`,
          role: ["branch_admin"],
        },
        {
          icon: "fa fa-tools",
          name: "Sozlamalar",
          path: "/settings",
          role: ["branch_admin"],
        },
      ].filter((item) => {
        return item.role.includes(role);
      });
    },
  },
};
</script>

<template>
  <button class="v-toggle-button" @click="$store.dispatch('toggleNav', true)">
    <i class="fas fa-bars"></i>
  </button>
  <div class="v-navigations" :class="{ open: show_nav }">
    <div class="v-logo-container">
      <img :src="`${url_to_files + logo}`" alt=" Image is not found" class="v-logo mb-2" />
    </div>
    <div class="v-nav-menu">
      <router-link class="v-nav-link" v-for="item in routers" :key="item" :class="item.path.replace('/', '')"
        active-class="active" :to="item.path">
        <i class="icon"><span :class="item.icon" /></i>
        {{ item.name }}
      </router-link>
    </div>
  </div>

  <!-- <router-link
    v-for="item in routers"
    :key="item"
    class="SidebarLink"
    :to="item.path"
    active-class="active"
  >
    v-if="role === 'branch_admin'"
    <p>{{ item.name }}</p>
  </router-link> -->
</template>

<style lang="css" scoped>
@import "./scss/style.scss";
</style>
